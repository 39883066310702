/**
 * @generated SignedSource<<a6d296bf0fb828e07b1739aed744ef7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobBudgetInterval = "DAY" | "HOUR" | "MONTH" | "TWO_WEEK" | "WEEK" | "YEAR" | "%future added value";
export type JobBudgetType = "FIXED_PRICE" | "RATE" | "%future added value";
export type JobDurationInterval = "DAY" | "MONTH" | "WEEK" | "%future added value";
export type PaidProjectInquirySource = "ATS_RECOMMENDED_INDEPENDENTS" | "DISCOVER" | "INDEPENDENT_PORTFOLIO" | "LISTS_MODAL" | "NETWORK" | "PROFILE" | "PROJECT" | "%future added value";
export type SendPaidProjectInquiryInput = {
  baseUrl?: ContraAPIScalars.SafeURL | null;
  budget?: string | null;
  calendarLink?: string | null;
  cc?: string | null;
  contractorUserProfileId?: string | null;
  duration?: string | null;
  emailAddress?: string | null;
  expertProgramId?: string | null;
  firstName?: string | null;
  inquirySource?: PaidProjectInquirySource | null;
  isOrganicTraffic?: boolean | null;
  jobId?: string | null;
  jobInput?: SendPaidProjectInquiryJobInput | null;
  jobPostingId?: string | null;
  lastName?: string | null;
  message: string;
  messageGreeting?: string | null;
  productizedServiceId?: string | null;
  redirectTo?: string | null;
  referrerUrl?: ContraAPIScalars.SafeURL | null;
  sendShortCode?: boolean | null;
  startDate?: string | null;
  timeline?: string | null;
};
export type SendPaidProjectInquiryJobInput = {
  budget?: JobBudgetInput | null;
  duration?: JobDurationInput | null;
  title: string;
};
export type JobBudgetInput = {
  estimatedHours?: number | null;
  feeMax?: any | null;
  feeMin: any;
  interval?: JobBudgetInterval | null;
  type: JobBudgetType;
};
export type JobDurationInput = {
  amount: number;
  interval: JobDurationInterval;
};
export type InquiryContextMutation$variables = {
  input: SendPaidProjectInquiryInput;
};
export type InquiryContextMutation$data = {
  readonly sendPaidProjectInquiry: {
    readonly inquirySent: boolean;
    readonly visitor: {
      readonly userAccount: {
        readonly emailAddress: string | null;
        readonly profile: {
          readonly firstName: string | null;
          readonly lastName: string | null;
        };
        readonly " $fragmentSpreads": FragmentRefs<"InquiryContextProvider_userAccount">;
      } | null;
    } | null;
  };
};
export type InquiryContextMutation = {
  response: InquiryContextMutation$data;
  variables: InquiryContextMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inquirySent",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailAddress",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InquiryContextMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SendPaidProjectInquiryPayload",
        "kind": "LinkedField",
        "name": "sendPaidProjectInquiry",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Visitor",
            "kind": "LinkedField",
            "name": "visitor",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserAccount",
                "kind": "LinkedField",
                "name": "userAccount",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserProfile",
                    "kind": "LinkedField",
                    "name": "profile",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "InquiryContextProvider_userAccount"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InquiryContextMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SendPaidProjectInquiryPayload",
        "kind": "LinkedField",
        "name": "sendPaidProjectInquiry",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Visitor",
            "kind": "LinkedField",
            "name": "visitor",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserAccount",
                "kind": "LinkedField",
                "name": "userAccount",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserProfile",
                    "kind": "LinkedField",
                    "name": "profile",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "filter",
                        "value": {
                          "source": "GENERAL_INQUIRY"
                        }
                      },
                      (v7/*: any*/)
                    ],
                    "concreteType": "UserAccountPaidProjectConnection",
                    "kind": "LinkedField",
                    "name": "paidProjects",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": "paidProjects(filter:{\"source\":\"GENERAL_INQUIRY\"},first:1)"
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "filter",
                        "value": {
                          "status": [
                            "APPROVED",
                            "ARCHIVED",
                            "PENDING",
                            "PENDING_REVISION",
                            "EXPIRED"
                          ]
                        }
                      },
                      (v7/*: any*/)
                    ],
                    "concreteType": "UserAccountCreatedJobPostingsConnection",
                    "kind": "LinkedField",
                    "name": "createdJobPostings",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": "createdJobPostings(filter:{\"status\":[\"APPROVED\",\"ARCHIVED\",\"PENDING\",\"PENDING_REVISION\",\"EXPIRED\"]},first:1)"
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5243114265b6d62cde6e62441e00a269",
    "id": null,
    "metadata": {},
    "name": "InquiryContextMutation",
    "operationKind": "mutation",
    "text": "mutation InquiryContextMutation(\n  $input: SendPaidProjectInquiryInput!\n) {\n  sendPaidProjectInquiry(input: $input) {\n    inquirySent\n    visitor {\n      userAccount {\n        emailAddress\n        profile {\n          firstName\n          lastName\n          id\n        }\n        ...InquiryContextProvider_userAccount\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment InquiryContextProvider_userAccount on UserAccount {\n  paidProjects(first: 1, filter: {source: GENERAL_INQUIRY}) {\n    count\n  }\n  createdJobPostings(first: 1, filter: {status: [APPROVED, ARCHIVED, PENDING, PENDING_REVISION, EXPIRED]}) {\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "8eb6fbd7c7756b5691d2e0b485409d2f";

export default node;
